<template>
  <footer>
    <b-container>
      <div class="py-4">
        <b-row>
          <b-col cols="4">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 502.6 502.6"
              height="120"
            >
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  d="M361.6,181.5l10.3,29.8-7.7-6.9,8.3,66.9s-.5,31.5-21.4,33.7-16.7-28.5-16.7-28.5-41.5,10.8-54.9,53.6a73.1,73.1,0,0,0,2.6,23.9c5.9,19.7,26.9,42.3,56.5,40.7,43.2-2.2,56-51.9,56.2-52.7,2.1-15.1-9.5-15.7-33.6-12.9-9.8,1.1-19,1.1-25.4-1-9.3-3.2-13.5-9.3-13.5-9.3s-.3,15.3,13.1,20a186.1,186.1,0,0,1,18.1,7,1.9,1.9,0,0,1,1.1,2.4l-4.3,11a1.9,1.9,0,0,1-2.4,1.2c-5-1.7-19.5-6.3-27.3-5.1-9.5,1.4-27.4,15-27.4,15a37.6,37.6,0,0,1-3.4-4.8,43.3,43.3,0,0,1-2.9-5.3s14.4-12.3,29.6-18.4c0,0-2.8-11.3-2.4-21.4s.6-32.2.6-32.2l8.7-5.2s-.7,20.8,12.3,27.9c7.4,4.1,13.7,5,22,4.9,6.3,0,13.3-1.5,21.6-1.6,19.3-.1,19.8,13.5,19.9,19.7,0,4.2-2,36.9-22.6,57.5-9.4,9.4-22.8,17.4-41.6,17.1-32-.6-50.5-19-57.4-39.8-4.1-12.4-3.1-25.7-.9-36.7,3.5-16.8,6-31,25.7-51.5,0,0-14.2-2.5-19.6-19.2a27.6,27.6,0,0,1,1.5-17.2c4-8.8,6.5-11.6,12.1-14.9s15.7-2.5,15.7-2.5v7.8s-20.3,4.2-23.9,10.3c-1.5,2.4-.2,16.4,16.7,20.6s40.8-5.5,47.4-8.6a.7.7,0,0,1,.9.9l-5.7,13.6-8.8,3.7s-2,13.5,10.4,14.5,16.5-4.3,16.4-5.6c-.2-3.2-10.3-86.8-10.3-86.8s.2-.7-.7-2.1-2.1-2-.5-6.4a76.5,76.5,0,0,1,4.3-9.1S359.9,177.4,361.6,181.5Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M338.8,234.1c4.6,1.4-4-4.5-13.6-6.3l.4,5.7S334.2,232.7,338.8,234.1Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M313.8,264.5l10.2-.8s0-68.3-5.5-101.4c0-.4-.6-.5-.8-.1-1.7,3.6-6.7,13.9-6.7,15.3A689.2,689.2,0,0,1,313.8,264.5Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M262.2,383.6c-7.1,6.4-15.6,12.7-24.4,10.8-22-4.5-1.6-37.7-4-38.7S203.4,409,241,412.1c10.1.8,20.2-9.8,28.2-18.4,6.4-7,11.8-12,11.8-12a20.5,20.5,0,0,1-2.9-5.2,38,38,0,0,1-2.2-6.2S269.8,376.8,262.2,383.6Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M222.7,342.8c9,.2,20-4.1,20-4.1s1.7,2.4-.3,8.3l-6.5,10.7s-6.4,18-6,24c0,0,21.5-19.9,20.5-47.9,0,0,12.5-9.7,14.5-24.8s.8-14-.2-17.6-6.9-12-7.2-14.1l7.6,3.2a1,1,0,0,0,1.3-1.5l-14.8-20.8a1,1,0,0,0-1.6.2l-4.7,12.5a2.1,2.1,0,0,0,1,2.7l1.4.7s-.9,3.7,2.9,12.2,10,19.3,10,20.9-4.3,8.7-12.3,12.8c0,0-8.2-17.7-21.7-17.2s-16.6,17.7-16.7,22.9S207.6,342.3,222.7,342.8Zm1.1-24.4c7.9-.3,12.5,6.2,12.5,6.2s-14.4,5.4-21.6-.2C214.7,324.4,216,318.7,223.8,318.4Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M166.8,396c-25.6-1.7-18.1-39.5-18.1-39.5l-1.6.7s-12.1,53.6,21.9,55c28.3,1.1,49.9-20,49.9-20a76.8,76.8,0,0,1,3.8-20.3C222.7,372,199.4,398.1,166.8,396Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M211.2,259.2l14.2,6.3a2.2,2.2,0,0,0,2.9-1.1l5.2-10.1a1.8,1.8,0,0,0-.9-2.5l-13.9-5.6a1.9,1.9,0,0,0-2.3.7l-6,9.7A1.8,1.8,0,0,0,211.2,259.2Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M151.9,380.9s33.8-19.8,35.3-47.6c1.2-19.7-10.6-31.2-10.6-31.2s7.4,3.8,8.1,3.2-13.8-18.4-17.5-23.1a.8.8,0,0,0-1.4.3L161.9,293a1.3,1.3,0,0,0,0,1.2,18.5,18.5,0,0,0,2.1,4.5c1.1,1.2,3.9,10.7,6.5,14.4s10.8,18.5,11.3,20.1-7.3,15.9-32.1,31.5C149.7,364.7,149.7,378.5,151.9,380.9Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M143,369.6c-32,22.1-86.6,40.6-86.6,40.6,6.6,1.1,32.4,1.5,44.5-1.9s41.9-21.4,41.9-21.4C142,383.6,143,369.6,143,369.6Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M274.9,192.4c-2.2-2.4-8.4,3-9.8,7.4s4.4,7,4.4,7c-9.2,9.9-32.3,17.1-32.3,17.1,19,.4,33.5-17,33.5-17a4,4,0,0,0,2.8,2.3c2.2.7.2-5.3.2-5.3C276.1,200.2,277.1,194.9,274.9,192.4Zm-2,11s-3.5-1.5-4.7-2.8,0-3.2,0-3.2a4.1,4.1,0,0,1,4.7-1.5C276.2,196.9,272.9,203.4,272.9,203.4Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M264.8,5.3h0l-.2.2c-.1.1-.1.1-.1.2-6.1,7.5-11.3,14.4-16,21l-.3.5c-8,11.4-17.4,26.2-24.3,41.6l-.2.2a185.8,185.8,0,0,0-6.5,17.5c-6,19.5-6.6,33.8-15.6,51s-17,29.1-25.6,41.6c-18.3,26.5-36.2,49.8-48.6,90.6-18.9,62.2,12.2,131.7,12.7,132.7-20.3-88.7,11.1-128.5,60-157.1-10.4,6.1-36.5,10-37.4,10.2,41-16.3,53.3-41.9,53.3-41.9-29.3,18-37.1,15.5-37.1,15.5,46.3-22.9,69.8-66.7,69.8-66.7-23.2,21.3-35.2,21.4-35.4,21.4,21.3-11.9,38.1-30.8,41.4-53.1s-3.6-27.8-4.9-55.7S264.8,5.3,264.8,5.3Z"
                  style="fill: #ffd400"
                />
                <path
                  d="M248.2,27.1c-8,11.5-17.4,26.3-24.3,41.6C229,57.1,236.5,43.6,248.2,27.1Z"
                  style="fill: #ffd400"
                />
                <path
                  d="M207.1,49c.2,11.1-5.2,29.7-5.5,30.8,1.2-9.6-1.3-23.8-1.3-23.8l-21,24.6c3.3,22.4-1.5,57.2-1.5,57.2-.4-16.5-6.7-36.1-6.7-36.1-8.6,40.3-77.3,61.2-83.4,124.4C82,284.6,114.2,307,114.2,307c7.1-108.9,77.2-140.6,103-220.5,1.1-3.4,2.2-7.1,3.8-11.1.9-2.2,1.8-4.3,2.8-6.4,0-.1,0-.2.1-.2,6.9-15.4,16.3-30.2,24.3-41.6l.3-.5c8-11.3,14.5-19.2,16-21,0-.1,0-.1.1-.2l.2-.2C248.2,15.7,207.1,49,207.1,49ZM107.2,280.7C68.3,204.8,149,157.4,149,157.4,86.3,214.3,107.2,280.7,107.2,280.7Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M144.5,408.2a.5.5,0,0,0-.9.6c6.5,13.9,38.1,67.8,128.7,61.1,61.1-4.5,97.7-37.8,108.6-49.3a.6.6,0,0,0-.8-1c-12.8,8.6-53.8,32.7-111.5,36.7C198.4,461.2,154.7,419.2,144.5,408.2Z"
                  style="fill: #ffd400"
                />
                <path
                  d="M365.5,162.5a1.3,1.3,0,0,0-2.2.6l-3.9,13a2.3,2.3,0,0,0,.7,2.5l1.1.8c24.4,19.8,88.5,94.2,38.4,220.5-.3.7.7,1.1.9.4C419.7,374.4,470.1,266.1,365.5,162.5Z"
                  style="fill: #ffffff"
                />
                <path
                  d="M285.6,164c-4.4-11.6-11.8-19.7-5.3-25.1,3.1,8.4,14.9-1,5.6-5.5-6.1-3-17.4,4.7-11.3,19.9,3.1,7.7,7.7,10.5,7.7,21.4,0,0-12.3-20.6-16.7-6-1.2,4.1,2,5.1,3,4.3,5.9-4.4,11.6,1.1,12.8,13.6a1.1,1.1,0,0,0,2,.6A25.3,25.3,0,0,0,285.6,164Z"
                  style="fill: #ffffff"
                />
              </g>
            </svg>
          </b-col>
          <b-col cols="4">
            <div class="d-flex flex-column justify-content-center h-100">
              <h5 class="text-white text-center">
                <span class="mdi mdi-map-marker"></span>
                سوريا- حلب - الجميلية
              </h5>
              <h5 style="direction: ltr" class="text-center text-white">
                +963 937 080 031
                <span class="mdi mdi-phone"></span>
              </h5>
              <h5 style="direction: ltr" class="text-center text-white">
                +963 937 080 031
                <span class="mdi mdi-phone"></span>
              </h5>
            </div>
          </b-col>
          <b-col b-col="4">
            <ul class="d-flex justify-content-between text-white">
              <li>
                <router-link class="text-white" to="/">الرئيسية</router-link>
              </li>
              <li>
                <router-link class="text-white" to="/panda">انا والباندا</router-link>
              </li>

              <li>
                <router-link class="text-white" to="/products">منتجاتنا</router-link>
              </li>
              <li>
                <router-link class="text-white" to="/join-us">انضم الينا</router-link>
              </li>
            </ul>
            <social-medias></social-medias>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="w-100 py-1 d-flex align-items-center justify-content-center text-white">
      <span> <strong> 2021 &copy; جميع الحقوق محفوظة </strong></span>
    </div>
  </footer>
</template>

<script>
import SocialMedias from "./SocialMedias.vue";
export default {
  components: {
    SocialMedias,
  },
};
</script>

<style lang="scss">
@import "../../../assets/variables.scss";
footer {
  background-color: $custom-dark !important;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    color: $g1 !important;
    padding: 15px 0;
  }
  h4 {
    color: $yello !important;
  }
  .socials {
    justify-content: center !important;
    gap: 20px;
    a {
      color: #fff !important;
    }
  }
}
</style>
