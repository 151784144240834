<template>
  <ul class="socials">
    <li>
      <a
        href="https://www.facebook.com/%D8%A7%D9%84%D8%B9%D9%84%D9%85-%D9%86%D9%88%D8%B1-102401248841238"
      >
        <span class="mdi mdi-facebook"></span>
      </a>
    </li>
    <li>
      <a href="https://chatwith.io/s/al-elm-nour">
        <span class="mdi mdi-whatsapp"></span>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/al_elm_nour/">
        <span class="mdi mdi-instagram"></span>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/AlElmNour1">
        <span class="mdi mdi-twitter"></span>
      </a>
    </li>
    <li>
      <a href="https://t.me/Al_Elm_Nour">
        <span class="mdi mdi-telegram"></span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  span {
    font-size: 34px;
  }
  a {
    color: #2b2b2b;
    cursor: pointer;
  }
}
</style>
